<template>
  <div id="subscribe" class="fill-height indigo lighten-5 d-flex align-center">
    <!-- Snackbar -->
    <v-snackbar v-model="snackbar">{{ snackbarText }}</v-snackbar>

    <!-- If not premium user : show comparison and plans -->
    <v-container v-if="!isUserSubscribed" class="px-0 px-md-12">
      <v-card class="rounded-xl pa-3 pa-md-9 pb-md-1 px-md-16" elevation="0">
        <!-- Heading Row -->
        <v-row>
          <v-col cols="12" sm="12" class="text-center">
            <h1 class="text-h4 text-dark-purple">
              <b>One Pass for all courses &amp; tests</b>
            </h1>
            <p class="text-light-purple">
              Packages made for you, no surprise fees.
            </p>
          </v-col>
        </v-row>

        <v-row align="center">
          <!-- Premium/Free Comparison -->
          <PremiumComparison
            :superLoading="loading"
            :features="features"
            :isUserSubscribed="isUserSubscribed"
            @setSuperLoading="setLoading"
          />

          <!-- Spacer col -->
          <v-col></v-col>

          <!-- Select Plan Card Buttons -->
          <template v-if="subPlans.length > 0 && !error">
            <PlanButtons
              ref="planButtonsComp"
              :superLoading="loading"
              :subPlans="subPlans"
              :discount="discount"
              :discountCode="discountCode"
              :auth="auth"
              :userData="userData"
              :discountPercentage="discountPercentage"
              @setSuperLoading="setLoading"
              @showSnackbar="showSnackbar"
              @setError="setError"
            />
          </template>
          <template v-else>
            <v-col cols="12" sm="12" md="5">
              <!-- Error fetching plans -->
              <div v-if="error" class="mx-auto">
                <v-alert type="error" text dense>
                  {{ error }}
                </v-alert>
              </div>

              <!-- Skeleton Loaders on PC -->
              <div v-else-if="loading && !(subPlans.length > 0)">
                <div v-if="!$vuetify.breakpoint.mobile">
                  <v-skeleton-loader
                    class="my-16"
                    min-width="40vw"
                    type="heading"
                  ></v-skeleton-loader>
                  <v-skeleton-loader
                    class="my-16"
                    min-width="40vw"
                    type="heading"
                  ></v-skeleton-loader>
                  <v-skeleton-loader
                    class="my-16"
                    min-width="40vw"
                    type="heading"
                  ></v-skeleton-loader>
                  <v-skeleton-loader
                    class="my-16"
                    min-width="40vw"
                    type="heading"
                  ></v-skeleton-loader>
                </div>

                <!-- Spinner on mobile -->
                <div v-else class="d-flex justify-center">
                  <v-progress-circular
                    indeterminate
                    color="deep-purple"
                    size="20"
                    class="mx-1"
                  ></v-progress-circular>
                </div>
              </div>
            </v-col>
          </template>
        </v-row>

        <v-divider class="my-6"></v-divider>

        <!-- Discount field -->
        <DiscountField
          :superLoading="loading"
          :discount="discount"
          :auth="auth"
          :discountPercentage="discountPercentage"
          @setSuperLoading="setLoading"
          @showSnackbar="showSnackbar"
          @discountCode="setDiscountCode"
          @enableDiscount="enableDiscount"
        />
      </v-card>
    </v-container>

    <!-- If premium user : show premium card and premium features -->
    <v-container v-else class="px-0 px-md-12">
      <v-row align="center" justify="center">
        <PremiumPassCard
          :superLoading="loading"
          :userData="userData"
          :subscribedTillDateText="subscribedTillDateText"
          @setSuperLoading="setLoading"
        />
      </v-row>

      <v-row align="center" justify="center" class="mt-9">
        <!-- Premium/Free Comparison -->
        <PremiumComparison
          :superLoading="loading"
          :features="features"
          :isUserSubscribed="isUserSubscribed"
          @setSuperLoading="setLoading"
        />
      </v-row>
    </v-container>

    <canvas v-show="isUserSubscribed" id="confettiCanvas"></canvas>
  </div>
</template>

<script>
var dayjs = require("dayjs");
const isSameOrBefore = require("dayjs/plugin/isSameOrBefore");
dayjs.extend(isSameOrBefore);

import PremiumComparison from "@/components/Subscribe/PremiumComparison.vue";
import DiscountField from "@/components/Subscribe/DiscountField.vue";
import PlanButtons from "@/components/Subscribe/PlanButtons.vue";
import PremiumPassCard from "@/components/Subscribe/PremiumPassCard.vue";

export default {
  name: "Subscribe",
  metaInfo: {
    title:
      "Premium Pass - One pass for all Online Mock Tests and Test series. Get 10,000+ questions with detailed solutions.",
    meta: [
      {
        vmid: "description",
        name: "description",
        content:
          "Plusinus provides high quality online Test series and Mock Tests for all DSSSB exams and other state level exams including CTET, HTET etc.",
      },
      {
        vmid: "keywords",
        name: "keywords",
        content:
          "Free Mock Tests, Test Series, Online Mock tests, DSSSB, DSSSB Exams",
      },
      {
        vmid: "robots",
        name: "robots",
        content: "index, nofollow",
      },
    ],
  },

  components: {
    PremiumComparison,
    DiscountField,
    PlanButtons,
    PremiumPassCard,
  },

  computed: {
    auth() {
      return this.$store.getters.auth;
    },
    userData() {
      return this.$store.getters.userData;
    },

    firstName() {
      return this.userData.fullName.split(" ")[0];
    },

    discountPercentage(){
      return this.$store.getters.discountPercentage;
    },

    isUserSubscribed() {
      const userDataObj = this.userData;
      if (userDataObj && "subscribedTill" in userDataObj) {
        const userSubscribedTill = dayjs(
          userDataObj.subscribedTill.seconds * 1000
        );
        return dayjs().isSameOrBefore(userSubscribedTill);
      }
      return false;
    },

    subscribedTillDateText() {
      if (!this.isUserSubscribed) {
        return;
      }
      return dayjs(this.userData.subscribedTill.seconds * 1000).format(
        "D MMM, YYYY"
      );
    },
  },

  watch: {
    isUserSubscribed: function (newVal) {
      // if user subscribes it turns to true
      if (newVal) {
        this.enableConfetti();
      }
    },
  },

  data: () => ({
    // Page State
    loading: true,
    discount: false,
    snackbar: false,
    snackbarText: "",
    error: "",
    nextUrl: "", //TODO: setup next-url

    // Data
    features: [
      { text: "Complete access to all Mock tests", premium: true },
      { text: "Handpicked questions", premium: true },
      { text: "Subject experts. One text away from you", premium: true },
      { text: "Detailed solutions for all exams", premium: true },
      { text: "Performance analysis", premium: false },
      { text: "Exam like interface", premium: false },
    ],

    subPlans: [],

    // Form Setup
    valid: false,

    discountCode: "",
  }),
  methods: {
    setLoading(value) {
      this.loading = value;
    },
    showSnackbar(text) {
      this.snackbarText = text;
      this.snackbar = true;
    },
    enableDiscount() {
      this.discount = true;
    },
    setDiscountCode(val) {
      this.discountCode = val;
    },
    enableConfetti() {
      const canvasEl = document.querySelector("#confettiCanvas");

      const w = (canvasEl.width = window.innerWidth);
      const h = (canvasEl.height = window.innerHeight * 2);

      function loop() {
        requestAnimationFrame(loop);
        ctx.clearRect(0, 0, w, h);

        confs.forEach((conf) => {
          conf.update();
          conf.draw();
        });
      }

      function Confetti() {
        //construct confetti
        const colours = ["#fde132", "#009bde", "#ff6b00"];

        this.x = Math.round(Math.random() * w);
        this.y = Math.round(Math.random() * h) - h / 2;
        this.rotation = Math.random() * 360;

        const size = Math.random() * (w / 60);
        this.size = size < 15 ? 15 : size;

        this.color = colours[Math.floor(colours.length * Math.random())];

        this.speed = this.size / 7;

        this.opacity = Math.random();

        this.shiftDirection = Math.random() > 0.5 ? 1 : -1;
      }

      Confetti.prototype.border = function () {
        if (this.y >= h) {
          this.y = h;
        }
      };

      Confetti.prototype.update = function () {
        this.y += this.speed;

        if (this.y <= h) {
          this.x += this.shiftDirection / 3;
          this.rotation += (this.shiftDirection * this.speed) / 100;
        }

        if (this.y > h) this.border();
      };

      Confetti.prototype.draw = function () {
        ctx.beginPath();
        ctx.arc(
          this.x,
          this.y,
          this.size,
          this.rotation,
          this.rotation + Math.PI / 2
        );
        ctx.lineTo(this.x, this.y);
        ctx.closePath();
        ctx.globalAlpha = this.opacity;
        ctx.fillStyle = this.color;
        ctx.fill();
      };

      const ctx = canvasEl.getContext("2d");
      const confNum = Math.floor(w / 4);
      const confs = new Array(confNum).fill().map(() => new Confetti());

      loop();
    },

    setError(val) {
      this.loading = false;
      this.error = val;
    },

    loadRazorPayScript() {
      this.loading = true;
      // remain this order (script.src after onLoad and append before onload)
      return new Promise((resolve) => {
        const scriptElement = document.getElementById("razorPayScript");
        if (!scriptElement) {
          var script = document.createElement("script");
          document.body.appendChild(script);
          script.onload = () => resolve(true);
          script.onerror = () => resolve(false);
          script.id = "razorPayScript";
          script.src = "https://checkout.razorpay.com/v1/checkout.js";
        } else {
          resolve(true);
        }
      });
    },

    fetchSubPlans() {
      this.loading = true;
      // get courses array from store
      const subPassesArr = this.$store.getters.subPasses;

      //if not found in store
      if (subPassesArr.length <= 0 || typeof subPassesArr == "undefined") {
        //get folder names from server
        this.$store
          .dispatch("getPremiumPassDoc")
          .then((res) => {
            if (res) {
              this.subPlans = res.passes;
            }
          })
          .catch((error) => {
            this.error = error;
          })
          .finally(() => {
            this.setLoading(false);
          });
      } else {
        // if found in store
        this.subPlans = subPassesArr;
        this.setLoading(false);
      }
    },

    startUserDocListener() {
      this.loading = true;
      this.$store
        .dispatch("getUserData")
        .catch((error) => {
          this.error = error;
        })
        .finally(() => {
          this.setLoading(false);
        });
    },
  },
  mounted() {
    if (this.isUserSubscribed) {
      this.enableConfetti();
    } else {
      this.loadRazorPayScript().then((scriptLoaded) => {
        if (!scriptLoaded) {
          // show error ask to reload
          this.error = "Network error, please reload :(";
        } else {
          this.loading = false;
        }
      });

      this.fetchSubPlans();

      if (this.auth) {
        this.startUserDocListener();
      }
    }

    // const selectedPlanIndex = this.$route.params.id;
    // if is a number
    // if (!isNaN(selectedPlanIndex)) {
    //   this.loading = true;
    //   this.$refs.planButtonsComp.openRazorPayDialog(selectedPlanIndex);
    // } else {
    //   this.loading = false;
    // }
  },
};
</script>

<style>
/* Confetti Canvas */
#confettiCanvas {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
}

/* End Confetti Canvas */

.text-dark-purple {
  color: #020346;
}

.text-light-purple {
  color: #9798b4;
}

#pill-container {
  max-width: 9.38rem;
}

.animated-gradient-bg {
  background: linear-gradient(314deg, #6a82fb, #fc5c7d);
  background-size: 400% 400%;

  -webkit-animation: AnimationName 10s ease infinite;
  -moz-animation: AnimationName 10s ease infinite;
  animation: AnimationName 10s ease infinite;
}

@-webkit-keyframes AnimationName {
  0% {
    background-position: 0% 53%;
  }
  50% {
    background-position: 100% 48%;
  }
  100% {
    background-position: 0% 53%;
  }
}
@-moz-keyframes AnimationName {
  0% {
    background-position: 0% 53%;
  }
  50% {
    background-position: 100% 48%;
  }
  100% {
    background-position: 0% 53%;
  }
}
@keyframes AnimationName {
  0% {
    background-position: 0% 53%;
  }
  50% {
    background-position: 100% 48%;
  }
  100% {
    background-position: 0% 53%;
  }
}
</style>