<template>
  <v-card
    id="premiumPassCard"
    class="
      rounded-lg
      pa-6
      text-center
      white--text
      d-flex
      align-center
      justify-center
      animated-gradient-bg
    "
    elevation="12"
  >
    <v-row class="d-flex align-center justify-center">
      <!-- Icon -->
      <v-col cols="12" sm="12" md="3">
        <v-icon
          class="mr-3 rounded-circle amber darken-2 pa-2"
          color="white darken-2"
          size="42"
        >
          mdi-crown-outline
        </v-icon>
      </v-col>

      <!-- Empty Spacer Col -->
      <v-col v-if="!$vuetify.breakpoint.mobile"> </v-col>

      <!-- Sub Till Date -->
      <v-col cols="12" sm="12" md="8" class="text-center text-md-left">
        <div class="text-h6">Premium Pass</div>
        <div class="my-1"><b>Name: </b> {{ userData.fullName }}</div>
        <div class="mt-2"><b>Valid Till: </b> {{ subscribedTillDateText }}</div>
      </v-col>
    </v-row>
  </v-card>
</template>
  
<script>
export default {
  name: "PremiumPassCard",
  props: ["superLoading", "userData", "subscribedTillDateText"],

  data: () => ({
    loading: true,
  }),

  methods: {
    setLoading(value) {
      this.loading = value;
      this.$emit("setSuperLoading", value);
    },
  },
  mounted() {
    this.loading = false;
  },
};
</script>
