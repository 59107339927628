<template>
  <v-col id="planButtons" cols="12" sm="12" md="5">
    <v-row v-if="loading || superLoading" justify="center">
      <v-alert type="info" dense prominent class="mt-12 text-center">
        <v-progress-circular
          indeterminate
          color="white"
          size="20"
          class="mx-1"
        ></v-progress-circular>
        Loading... Please wait :)
      </v-alert>
    </v-row>
    <v-row v-show="!(loading || superLoading)">
      <v-item-group v-model="selectedPlan" @change="stageRazorPayDialog()">
        <v-row class="mx-1">
          <v-col v-for="(plan, i) in subPlans" :key="i" cols="12" sm="12">
            <!-- Plan Item -->
            <v-item v-slot="{ active, toggle }">
              <v-card
                class="
                  pa-5
                  rounded-lg
                  white--text
                  d-flex
                  align-center
                  justify-space-between
                  animated-gradient-bg
                "
                :disabled="loading || superLoading"
                @click="toggle"
                outlined
              >
                <!-- Icon -->
                <span>
                  <v-icon :color="active ? 'white' : 'white'">
                    {{ active ? "mdi-check-circle" : "mdi-circle-outline" }}
                  </v-icon>
                </span>

                <!-- Card Text -->
                <!-- Name and Discount -->
                <div>
                  <div>
                    {{ plan.name }}
                  </div>
                  <div v-if="discount && plan.discountApplies">
                    <v-chip color="amber accent-3" x-small
                      >{{ discountPercentage }}% off</v-chip
                    >
                  </div>
                </div>

                <!-- Price -->
                <div>
                  <div v-if="discount && plan.discountApplies">
                    Rs. {{ plan.priceDiscount }}
                  </div>
                  <div
                    :class="
                      discount && plan.discountApplies
                        ? 'text-decoration-line-through'
                        : ''
                    "
                  >
                    Rs. {{ plan.price }}
                  </div>
                </div>
              </v-card>
            </v-item>
          </v-col>
        </v-row>
      </v-item-group>
    </v-row>
  </v-col>
</template>
  
<script>
export default {
  name: "PlanButtons",
  props: [
    "superLoading",
    "subPlans",
    "discount",
    "discountCode",
    "auth",
    "userData",
    "discountPercentage",
  ],

  data: () => ({
    loading: true,
    selectedPlan: "",
  }),

  methods: {
    setLoading(value) {
      this.loading = value;
      this.$emit("setSuperLoading", value);
    },

    loadRazorPayScript() {
      // remain this order (script.src after onLoad and append before onload)
      return new Promise((resolve) => {
        const scriptElement = document.getElementById("razorPayScript");
        if (!scriptElement) {
          var script = document.createElement("script");
          document.body.appendChild(script);
          script.onload = () => resolve(true);
          script.onerror = () => resolve(false);
          script.id = "razorPayScript";
          script.src = "https://checkout.razorpay.com/v1/checkout.js";
        } else {
          resolve(true);
        }
      });
    },

    stageRazorPayDialog() {
      //check if auth
      //if not auth send to login with data
      if (!this.auth) {
        this.$router.push({
          path: "/login",
          query: { nextUrl: "/premium-pass/" + this.selectedPlan },
        });
      } else {
        //if auth open
        this.openRazorPayDialog(this.selectedPlan);
      }
    },

    openRazorPayDialog(planIndex) {
      // return if if not number or if invalid plan index
      if (
        isNaN(planIndex) ||
        planIndex > this.subPlans.length ||
        planIndex < 0
      ) {
        return;
      }

      this.setLoading(true);
      this.selectedPlan = planIndex;

      this.loadRazorPayScript().then((scriptLoaded) => {
        if (!scriptLoaded) {
          // throw error ask to reload
          this.$emit("setError", "Network error, please reload :(");
          return;
        } else {
          // Continue to create order and open dialog

          // {planIndex, discountCode }
          const payload = {
            planIndex: this.selectedPlan,
            discountCode: this.discountCode,
          };

          this.$store
            .dispatch("getOrderID", payload)
            .then((orderRes) => {
              // {amount, orderID, planName}
              if (orderRes) {
                this.constructDialogWithOptions(orderRes);
              } else {
                console.log("Order Error. Error Code: Order_1 ");
              }
            })
            .catch((error) => {
              this.$emit("setError", error);
              console.log("Order Error: ", error);
              this.setLoading(false);
            });
        }
      });
    },

    // argument {amount, orderID, planName}
    constructDialogWithOptions(orderRes) {
      var _this = this;

      var options = {
        key: "rzp_live_w7odpdhEWbwpUa",
        amount: parseInt(orderRes.amount), // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
        currency: "INR",
        name: orderRes.planName,
        description: "PlusMinus Premium Pass",
        image:
          "https://firebasestorage.googleapis.com/v0/b/plusminus-b973d.appspot.com/o/site_logos%2Flogo.png?alt=media&token=310d34ee-ff36-47f9-8544-2a6f25dc9532",
        order_id: orderRes.orderID,
        modal: {
          ondismiss: function () {
            _this.setLoading(false);
          },
        },
        prefill: {
          name: this.userData.fullName,
          email: this.userData.email,
          contact: this.userData.phone,
        },
        notes: {
          uid: this.userData.uid,
          discountCode: this.discountCode,
          planName: this.subPlans[this.selectedPlan].name,
          extends_days: this.subPlans[this.selectedPlan].extends_days,
        },
        theme: {
          color: "#6a82fb",
        },
      };
      var paymentObj = new window.Razorpay(options);
      paymentObj.open();
    },
  },
  mounted() {
    this.loadRazorPayScript().then((scriptLoaded) => {
      if (!scriptLoaded) {
        // throw error ask to reload
        this.$emit("setError", "Network error, please reload :(");
      } else {
        this.loading = false;
      }
    });
  },
  beforeDestroy() {
    const scriptElement = document.getElementById("razorPayScript");
    if (scriptElement) {
      document.body.removeChild(scriptElement);
    }
  },
};
</script>
