<template>
  <v-form
    v-if="!discount"
    id="discountField"
    v-model="valid"
    @submit.prevent="applyDiscountCode()"
    ref="discountForm"
  >
    <v-row>
      <v-spacer></v-spacer>
      <!-- If not auth: login link -->
      <v-col
        v-if="!auth"
        cols="12"
        md="6"
        class="pb-5 d-flex align-center justify-end"
      >
        Please
        <v-btn @click="toLogin()" color="blue" class="px-0 mx-1" text small
          >login</v-btn
        >
        to apply coupons
      </v-col>

      <!-- if auth: discount field -->
      <v-col v-else cols="12" md="4" class="d-flex align-center justify-end">
        <!-- Discount Field -->
        <v-text-field
          v-model="discountCode"
          color="deep-purple accent-4"
          type="text"
          :rules="discountCodeRules"
          label="Discount Code"
          :disabled="loading || superLoading"
          @keydown.enter.prevent="applyDiscountCode()"
          filled
          rounded
          required
        ></v-text-field>

        <!-- Discount Code Submit Button -->
        <v-btn
          icon
          color="pink"
          class="mt-n7 ml-1"
          :disabled="loading || superLoading || !valid"
          @click="applyDiscountCode()"
        >
          <v-icon>mdi-chevron-right</v-icon>
        </v-btn>
      </v-col>
    </v-row>
  </v-form>
</template>
  
<script>
export default {
  name: "DiscountField",
  props: ["superLoading", "discount", "auth", "discountPercentage"],

  data: () => ({
    loading: true,
    valid: false,
    discountCodeRules: [
      (value) => !!value || "",
      (value) => value.length >= 3 || "Minimum 3 charachters required",
    ],
    discountCode: "",
  }),

  methods: {
    setLoading(value) {
      this.loading = value;
      this.$emit("setSuperLoading", value);
    },

    toLogin() {
      this.$router.push({
        path: "/login",
        query: { nextUrl: "/premium-pass" },
      });
    },

    applyDiscountCode() {
      // Early return if no discount Code
      if (!this.discountCode) {
        return;
      }

      //convert input to lower-case
      const discountCode = this.discountCode.toLowerCase();

      this.$store
        .dispatch("checkDiscountCode", discountCode)
        .then((discountValid) => {
          this.$emit("discountCode", this.discountCode);

          if (discountValid) {
            this.$emit("enableDiscount");
            this.$emit(
              "showSnackbar",
              `${this.discountPercentage}% off applied! Congratulations!`
            );
          } else {
            this.$emit("showSnackbar", `Discount Code invalid :(`);
          }
        })
        .catch((error) => {
          this.$emit(
            "showSnackbar",
            `Error in checking discount code: `,
            error
          );

          console.log("Discount Code Error: ", error);
        })
        .finally(() => {
          this.setLoading(false);
        });

      //check if exists in array,
    },
  },
  mounted() {
    this.loading = false;
  },
};
</script>
