<template>
  <v-col id="premiumComparison" cols="12" sm="12" md="6">
    <v-sheet class="pa-5 indigo lighten-5 fill-height rounded-lg">
      <!-- Pilled vhip container-->
      <div
        v-if="!isUserSubscribed"
        id="pill-container"
        class="rounded-pill white"
      >
        <!-- Premium Chip -->
        <v-chip
          :class="activeChip == 'premium' ? 'deep-purple white--text' : 'white'"
          @click="activeChip = 'premium'"
        >
          <v-icon class="mr-1" color="amber darken-2">mdi-crown-outline</v-icon>
          Premium
        </v-chip>

        <!-- Free Chip -->
        <v-chip
          :class="activeChip == 'free' ? 'deep-purple white--text' : 'white'"
          @click="activeChip = 'free'"
        >
          Free
        </v-chip>
      </div>

      <div v-else>
        <p class="text-h6 text-center">Enjoy premium features :</p>
      </div>

      <!-- Features list -->
      <v-list disabled class="transparent">
        <v-list-item-group color="primary">
          <v-list-item v-for="(feature, i) in features" :key="i">
            <!-- if free chip and premium feature: strikethrough text  -->
            <v-list-item-content
              :class="
                activeChip != 'premium' && feature.premium
                  ? 'text-decoration-line-through'
                  : ''
              "
            >
              <v-list-item-title
                class="font-weight-medium"
                v-text="feature.text"
              ></v-list-item-title>
            </v-list-item-content>

            <v-list-item-icon>
              <!-- if free chip and feature premium grey close icon else indigo check -->
              <v-icon
                :color="
                  activeChip != 'premium' && feature.premium
                    ? ''
                    : 'deep-purple'
                "
              >
                {{
                  activeChip != "premium" && feature.premium
                    ? "mdi-close-circle"
                    : "mdi-check-circle"
                }}
              </v-icon>
            </v-list-item-icon>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-sheet>
  </v-col>
</template>
  
<script>
export default {
  name: "PremiumComparison",
  props: ["superLoading", "features", "isUserSubscribed"],

  data: () => ({
    loading: true,
    activeChip: "premium",
  }),

  methods: {
    setLoading(value) {
      this.loading = value;
      this.$emit("setSuperLoading", value);
    },
  },
  mounted() {
    this.loading = false;
  },
};
</script>
